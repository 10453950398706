<template>
  <div class="table-wrap">
    <div class="table-container">
      <div class="table-tabs">
        <a-radio-group size="large" button-style="solid" v-model:value="filter.is_new" @change="handleSearch">
          <a-radio-button value="1">当前匹配</a-radio-button>
          <a-radio-button value="2">历史匹配</a-radio-button>
        </a-radio-group>
      </div>
      <div class="table-toolbar">
        <div class="title">
          <div class="text">
            <span>政策匹配</span>
          </div>
        </div>
        <div class="filters">
          <a-select class="filter" placeholder="请选择类型"  @change="change()" v-model:value="filter.year"
            :options="yearsList"></a-select>
          <a-button class="filter-btn" type="primary" :disabled="disabled" @click="gomatch">执行匹配</a-button>
        </div>
      </div>
      <div class="table">
        <a-table size="middle" :columns="columns" :row-key="record => record.id" :data-source="list"
          :pagination="pagination" :loading="loading" @change="handleChange">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex == 'dept_id'">
              <div>
                <span>{{ record.dept_info?.dept_name }}</span>
              </div>
            </template>
            <template v-if="column.dataIndex == 'declare_item'">
              <div>
                <span>{{ record?.article_info.declare_item_info?.name }}</span>
              </div>
            </template>
            <template v-if="column.dataIndex == 'amount'">
              <div>
                <span>{{ record.article_info?.amount }}万元</span>
              </div>
            </template>
            <template v-if="column.dataIndex == 'jzrq'">
              <div>
                <span>{{ record.article_info?.jzrq }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'num'">
              <div>
                <span class="numBlock" :class="{ blue: record.num - 0 >= 60, gray: record.num - 0 < 60 }">{{ record.num
                }}%</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'release_time'">
              <div>
                <span>{{ record.article_info?.release_time?.substring(0, 10) }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'title'">
              <div class="data-title" @click="toUrl(record)">
                <a-tooltip placement="topLeft" :title="record.article_info?.title" :color="'geekblue'">
                  <span>{{ record.article_info?.title }}</span>
                </a-tooltip>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'opt'">
              <div class="operations">
                <a-button class="btn" type="info" size="small" @click="showDetail(record)">查看</a-button>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>
  <EditDetail ref="editDetailRef" />
</template>
<script>
import service from '@/service/service';
import EditDetail from './EditDetail.vue';
// import { message } from 'ant-design-vue';

export default {
  data() {
    return {
      list: [],
      filter: {
        //year: '',
        is_new:"1"
      },
      disabled:true,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      loading: false,
      companyList: [],
      yearsList:[],
      columns: [
        {
          title: '发布日期',
          dataIndex: 'release_time'
        },
        {
          title: '部门',
          dataIndex: 'dept_id',
        },
        {
          title: '政策名称',
          dataIndex: 'title',
          width: 400,
        },
        // {
        //   title: "申报项目",
        //   dataIndex: "declare_item",
        // },
        {
          title: "奖励金额",
          dataIndex: "amount",
        },
        {
          title: "申报截止",
          dataIndex: "jzrq",
        },

        {
          title: '匹配度',
          dataIndex: 'num',
        },
        {
            title: "匹配详情",
            dataIndex: "opt"
        }
      ],
    }
  },
  mounted() {
    this.init();
    //this.handleSearch();
  },
  methods: {
    handleChange(e) {
      this.pagination = { ...e };
      this.handleSearch();
    },
    change(){
      ///console.log("Aaa")
      this.disabled = false
    },
    showDetail(item) {
            console.log(item);
            this.$refs['editDetailRef']?.onShow(item.id);
        },
    toUrl(item) {
      //window.open(item.article_info?.url)
      this.$router.push('/prev/policyDetail?id='+item.article_id)
    },

    async init() {
      this.loading = true;
      const { data } = await service.get_years();
      this.loading = false;
      this.yearsList = data?.map(m => ({
        label: m.year,
        value: m.value
      })) || [];
      //console.log(this.yearsList)
    },
    gomatch(){
      this.filter.is_new = "1";
      this.pagination.current = "1"
      let newmatch = "1"
      this.handleSearch(newmatch);
    },
    async handleSearch(newmatch='') {
      this.loading = true;
      let params = {
        //company_id: this.filter.company_id,
        page: this.pagination.current,
        is_new:this.filter.is_new
      };
      let res = {}
      console.log(newmatch)
      //return false
      if(newmatch == 1){
        res = await service.match_article({ type: this.filter.year,is_new:this.filter.is_new});
      }else{
        res = {code:1}
      }
      
      if (res?.code) {
        const res = await service.get_match_article_lists(params);
        this.pagination = { ...this.pagination, total: res.total_number }
        // console.log(data);
        this.list = res.data;
      }
      this.loading = false;
    }
  },
  components: { EditDetail }
}
</script>
<style lang="less" scoped>
.table-wrap {
  margin: 24px 0;
  width: 1400px;
}

.numBlock {
    padding: 0 12px;
    height:30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    min-width: 60px;

    &.blue {
      background: #3399FF;
    }

    &.gray {
      background: #787878;
    }
    
  }

.table {
  .data-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      color: #3399FF;
    }
  }
}
</style>