<template>
  <div>
    <a-drawer title="匹配详情" :width="1000" :visible="visible" :body-style="{ paddingBottom: '80px' }"
      :footer-style="{ textAlign: 'right' }" @close="onClose">

      <a-table :dataSource="dataSource" :columns="columns" :pagination="false" class="ant-table-striped" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex == 'flname'">
            <div>
              <span>{{ record.flname }}</span>
            </div>
          </template>
          <template v-if="column.dataIndex == 'company_value'">
            <div>
              <span>{{ record.company_value }}{{ record.unit }}</span>
            </div>
          </template>
          <template v-if="column.dataIndex == 'article_value'">
            <div>
              <span>{{ record.article_value }}{{ record.unit }}</span>
            </div>
          </template>
          <template v-else-if="column.dataIndex == 'ydb'">
            <div>
              <span v-if="record.ydb">
                <img src="./ydb.png" style="width:18px;height:18px;" alt="" class="imgIcon">
              </span>
            </div>
          </template>
          <template v-else-if="column.dataIndex == 'wdb'">
            <div>
              <span v-if="record.wdb">
                <img src="./wdb.png" style="width:18px;height:18px;" alt="" class="imgIcon">
              </span>
            </div>
          </template>
        </template>
      </a-table>

      <template #extra>
        <a-space>
          <a-button @click="onClose">关闭</a-button>
          <!-- <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button> -->
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
// import UploadFile from '@/components/common/uploadFile.vue';
import service from '@/service/service';
const rules = {
  zclx: [{
    required: true,
    message: '请选择政策类型',
  }],
};
export default {
  components: {
  },
  data() {
    return {
      rules,
      dbData: [],
      wdbData: [],
      visible: false,
      createLoading: false,
      columns: [
        {
          title: '指标分类',
          dataIndex: 'flname',
          width:'200'
        },
        {
          title: '指标名称',
          dataIndex: 'name',
        },
        {
          title: '企业数据',
          dataIndex: 'company_value',
        },
        {
          title: '政策要求',
          dataIndex: 'article_value',
        },
        {
          title: '已达标',
          dataIndex: 'ydb'
        },
        {
          title: '未达标',
          dataIndex: 'wdb'
        },
      ],
      dataSource: []
    }
  },
  watch: {

  },
  methods: {
    async getOptions(id) {
      let res = await service.get_match_detail({
        id: id
      });
      let temp = [];
      res.data.weidabiao_data.forEach(f => {
        f.tag_data.forEach(tag => {
          temp.push({
            flname: f.name,
            name: tag.name,
            company_value: tag.company_value,
            unit: tag.tag_info.unit,
            article_value: tag.article_value,
            ydb: 0,
            wdb: true
          })
        })
      })
      res.data.dabiao_data.forEach(f => {
        f.tag_data.forEach(tag => {
          temp.push({
            flname: f.name,
            name: tag.name,
            company_value: tag.company_value,
            unit: tag.tag_info.unit,
            article_value: tag.article_value,
            ydb: true,
            wdb: 0
          })
        })
      })
      console.log(temp);
      this.dataSource = temp;
    },
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },

    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow(id) {
      this.visible = true;
      this.getOptions(id);
    },

    handleInput(tag, e) {
      if (e.target.value) {
        tag.selected = true;
      }
      else {
        tag.selected = false;
      }
    },

  }
}
</script>

<style lang="less">
.selectFormItem {
  .ant-form-item-label {
    label {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      padding: 0 6px;
      background: #3399ff;
      line-height: 32px;
      border-radius: 3px;
    }
  }
}
</style>
<style lang="less" scoped>
.checkbox-wrap {
  max-height: 32px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 12px;

  .name {
    font-size: 14px;
    color: #666;
    padding: 0 8px;

    &.red {
      color: red;
    }
  }

  .value {
    font-size: 14px;
    color: #333;

    &.red {
      margin-left: 12px;
      color: red;
    }
  }
}
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.box {
  .tit {
    font-size: 18px;
    padding-left: 32px;
    border-left: 4px solid #999;
    height: 44px;
    line-height: 44px;
    margin-bottom: 24px;
  }

  .box-inner {
    padding: 12px;
  }
}
</style>